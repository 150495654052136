input {
    outline: none;
}

input[type=search] {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
    display: none;
}

input[type=search] {
    border: solid 1px rgb(159, 159, 159);
    padding: 9px;
    width: 85%;
    border-radius: 5px;
    transition: all .5s;
    -webkit-border-radius: 5px;
    -webkit-transition: all .5s;
    -moz-border-radius: 5px;
    -moz-transition: all .5s;
}

input:-moz-placeholder {
    color: #000;
}

input::-webkit-input-placeholder {
    color: #000;
}