.chat {
  height: 100vh;
}
.chat .container {
  padding: 0 30px;
  height: 80%;
}
.chat .container.message-form {
  height: 10%;
  margin-top: 5%;
}
.chat h1, .chat h3 {
  margin-top: 0;
  color: #0084ff;
}
.chat input {
  color: #7e99a0;
  font-size: 1em;
  font-weight: 300;
  line-height: 19px;
  padding: 5px;
  box-sizing: border-box;
  display: inline-block;
  width: 80%;
  float: left;
}
.chat button {
  background-color: #0084ff;
  border-color: #0084ff;
  border-style: solid;
  color: white;
  font-size: 1em;
  padding: 5px;
  width: 20%;
  float: left;
}
.chat ul {
  list-style: none;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 80%;
}
.chat ul li {
  clear: both;
  display: block;
  margin-bottom: 5px;
  max-width: 47%;
  min-width: 8%;
}
.chat ul li p {
  border-radius: 100px / 50px;
  padding: 5px 20px;
  margin: 0;
}
.chat ul li .author {
  color: grey;
  display: inline-block;
  text-align: center;
  padding: 5px;
  margin: 0 2%;
  font-weight: 300;
  font-size: 12px;
}
.chat .him {
  float: left;
  text-align: left;
}
.chat .him p {
  background: #25cf41;
  color: #fff;
}
.chat .me {
  float: right;
  text-align: right;
}
.chat .me .author {
  display: none;
}
.chat .me p {
  background: #0084ff;
  color: #fff;
}
.chat .him + .me p {
  border-bottom-right-radius: 5px;
}
.chat .him + .me p {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.chat .me:last-of-type p {
  border-bottom-right-radius: 30px;
}

.rightAlignBox
{
  display: inline-block;
  background: #788bb3;
  border-radius: 5px 0 5px 5px;
  float: right;
  position: relative;
  color: #fff;
  padding: 6px 20px;
}
.rightAlignBox:after {
  content: "";
  position: absolute;
  right: -7px;
  top: -8px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-right-color: #788bb3;
}
.leftAlignBox
{
  display: inline-block;
  background: #dfe9ff;
  border-radius: 0 5px 5px 5px;
  position: relative;
  padding: 6px 20px;
}
.leftAlignBox:after {
  content: "";
    position: absolute;
    left: -7px;
    top: -7px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 7px solid transparent;
    border-bottom-color: #dfe9ff;
}
.MuiGrid-grid-xs-3{
  background: #f3f4ff !important;
}